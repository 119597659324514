/* Calendar styles */
.rbc-calendar {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 14px;
}

/* Event styles */
.rbc-event {
  background-color: #8d5eb0;
  border-radius: 4px;
  padding: 4px;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.rbc-event-content {
  color: white;
  font-weight: 600;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

/* Agenda view specific styles */
.rbc-agenda-view table.rbc-agenda-table {
  border-collapse: separate;
  border-spacing: 0 1px;
}

.rbc-agenda-view table.rbc-agenda-table tbody>tr>td:first-child {
  width: 140px;
  min-width: 140px;
  max-width: 140px;
  overflow: visible;
  white-space: nowrap;
  border-left: 1px solid #ddd;
  /* font-weight: bold; */
  /* color: #333; */
}

.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  padding-right: 10px;
  padding-left: 10px;
}

.rbc-agenda-view table.rbc-agenda-table tbody>tr>td {
  padding: 8px;
  border-bottom: 1px solid #eee;
}

.rbc-agenda-view table.rbc-agenda-table tbody>tr:hover {
  background-color: #f5f5f5;
}

/* Style for all-day events */
.rbc-agenda-view .rbc-agenda-event-cell {
  font-weight: 600;
}