
.booking-header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #f8f8f8;
    flex-shrink: 0;
}

.logo {
    font-size: 24px;
    font-weight: bold;
}

.auth-buttons button {
    margin-left: 10px;
    padding: 10px 20px;
    border: none;
    background-color: #6200ee;
    color: white;
    cursor: pointer;
}

.auth-buttons button:hover {
    background-color: #3700b3;
}
.list h2 {
    text-align: center;
    margin-bottom: 20px;

}
.list-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    overflow: auto;
}

.consultant-list h2 {
    text-align: center;
    margin-bottom: 20px;

}
.consultant-list-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}
.consultant-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    text-align: left;
    width: 32.5%;
    background-color: #FCFCFC;
}

.location-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    background-color: #FCFCFC;
}


.location-card img{
    width: 250px;
    padding-bottom: 20px;
}