@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* *::-webkit-scrollbar,
*::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-track {
  display: none;
} */

*:not(.addScrollBar)::-webkit-scrollbar,
*:not(.addScrollBar)::-webkit-scrollbar-thumb,
*:not(.addScrollBar)::-webkit-scrollbar-track {
  display: none;
}

.addScrollBar::-webkit-scrollbar {
  display: block;
  width: 12px; /* Adjust the width of the scrollbar */
}

.addScrollBar::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.addScrollBar::-webkit-scrollbar-track {
  background: lightgrey;
}

.add-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

input[type='color'] {
  -webkit-appearance: none;
  border: none;
}
input[type='color']::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type='color']::-webkit-color-swatch {
  border: none;
  border-radius: 100%;
}

.step-item:not(:first-child):before {
  @apply content-[''] bg-neutral-100 absolute w-full h-[2px] top-1/3 right-2/4 -translate-y-2/4 z-[-1];
}
.step-active:not(:first-child):before {
  @apply bg-primary-500;
}

.step-item-error:not(:first-child):before {
  @apply content-[''] bg-neutral-100 absolute w-full h-[2px] top-1/3 right-2/4 -translate-y-2/4 z-[-1];
}
.step-active-error:not(:first-child):before {
  @apply bg-destructive-500;
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  .responsive-height {
    height: 91vh; /* Example height for extra small devices */
    overflow-y: auto;
  }
}

/* Small devices (phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .responsive-height {
    overflow-y: auto;
    height: 91vh; /* Example height for small devices */
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .responsive-height {
    overflow-y: auto;
    height: 91vh; /* Example height for medium devices */
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .responsive-height {
    overflow-y: auto;
    height: 87vh; /* Example height for large devices */
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .responsive-height {
    overflow-y: auto;
    height: 91vh; /* Example height for extra large devices */
  }
}

/* @media (orientation: landscape) and (min-height: 592px)and (max-height: 832px){
  .responsive-height {
    background-color: lightblue; 
    height: 87vh;
  }
}

@media (orientation: portrait) {
  .responsive-height {
    background-color: lightcoral; 
  }
} */

@responsive {
  .custom-height {
    height: 82vh; /* Default height */
  }

  @screen md {
    .custom-height {
      height: 82vh; /* Height for medium screens */
    }
  }

  @screen lg {
    .custom-height {
      height: 89vh; /* Height for large screens */
    }
  }

  @screen xl {
    .custom-height {
      height: 87vh; /* Height for extra large screens */
    }
  }
}

/* Quill Editor Overrides */

.quill {
  .ql-toolbar {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
  }

  .ql-container {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
}


